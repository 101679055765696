//eventDetails.js
// this component shows the data from a document inside the 'events' collection
// Each event document has a field called gymId which contains the id of one of the gyms in the gyms collections.
//     Some events also have a leagueId which is the id of a league from the leagues collection.
//     Each gym and league has a field called ownerUid which is a id of a user from the users collection.
//
//     For each event I would like to find the corresponding gym and league and get the data for that gym and league.
//
//     Once that data has been found, if the logged in user is the admin of either the gym or the league then allow them to see the <EditEventDetails component on line 256//TODO make it so that the person who created the event can edit it

//TODO: make it check if there is a league assigned to the event, if there is allow any of the league admins to edit it
//TODO: add a MUI <Switch /> to the event page, when the user clicks the <Switch /> it adds the event id to an array on the 'user' called 'eventSubscriptions'. If they disable the <Switch /> it removes it from the array
//TODO: create a list of all the users who have subscribed for the event
// TODO: allow the gym manager to add a register for this event button which linkns away to the official page
//TODO can we careate a calendar file from the event
// TODO: allow admin to define the obstacle - use this obstacle list when entering results
// TODO: Add a 'add to calendar' button
import React, { useCallback, useContext, useEffect, useState, Suspense } from "react";

import AuthContext from '../../contexts/AuthContext';
import { Link, useParams } from 'react-router-dom';
import { useDataLayer } from '../data/DataLayer';
import IsSubscribedSwitch from "../user/isSubscribedSwitch";
import SubmitResultsForm from "./results/SubmitResultsForm";
const GymCard = React.lazy(() => import("../gyms/GymCard"));
const LeagueCard = React.lazy(() => import("../leagues/LeagueCard"));
import {formatDate} from '../data/formatDate';
import EditEventDetails from './EditEventDetails';

import { onSnapshot, getFirestore, doc, getDoc, updateDoc, getDocs, query, collection, where, deleteDoc} from 'firebase/firestore';
import {auth} from "../../FirebaseSetup";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

//mui
import { Card, CardHeader, Avatar, IconButton, CardMedia, CardContent, Typography, CardActions, Button, Box } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ResultsDisplay from "./ResultsDisplay";

//Obstacle list
import ObstacleList from './results/ObstacleList'; // Adjust path as necessary
import Modal from '@mui/material/Modal'; // If using MUI for modal




const EventDelete = styled(Button)`
  margin: 0 0 0 1rem;
  padding: 0;
  min-width: 16px;
`

const EventDetails = ( userType, handleDelete, ) => {
    const { id } = useParams();
    const { events, gyms, leagues, getEventById: originalGetEventById } = useDataLayer();
    //TODO check authcontext implementation
    const { currentUser } = useContext(AuthContext);
    const [event, setEvent] = useState(null);
    const [open, setOpen] = useState(false); // state for dialog open/closed
    const [searchBox, setSearchBox] = useState(null);
    const [error, setError] = useState("");
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [subscribedUsers, setSubscribedUsers] = useState([]);
    const [editEventOpen, setEditEventOpen] = useState(false); // state for edit event dialog open/closed
    const [selectedEvent, setSelectedEvent] = useState(null); // state for selected event to edit
    const [gym, setGym] = useState(null); // Add a state for the gym data
    const [league, setLeague] = useState(null); // Add a state for the league data
    const [results, setResults] = useState([]);
    const [obstacles, setObstacles] = useState([]);
    const [isObstacleListOpen, setIsObstacleListOpen] = useState(false);

    const getEventById = useCallback((id) => {
        return originalGetEventById(id);
    }, [events]);

    // find the matching gym basd on the gym id in the event
    const getGymById = useCallback((gymId) => {
        const gym = gyms.find((g) => g.id === gymId);
        return gym || { error: 'Gym not found' };
    }, [gyms]);



    //find the matching league based on the id in the event
    const getLeagueById = useCallback((leagueId) => {
        const league = leagues.find((l) => l.id === leagueId);
        return league || { error: 'League not found' };
    }, [leagues]);


    useEffect(() => {
        console.log('useEffect for fetching event, gym, and league data is running. Id: ', id);

        const fetchData = async () => {
            const eventData = await getEventById(id);
            setEvent(eventData);

            if (eventData) {
                const gymData = eventData.gymId ? await getGymById(eventData.gymId) : null;
                setGym(gymData);

                const leagueData = eventData.leagueId ? await getLeagueById(eventData.leagueId) : null;
                console.log('Fetched League Data:', leagueData);
                setLeague(leagueData);
            }
        };

        fetchData();

        fetchData();

        // Real-time update for obstacles
        const eventRef = doc(getFirestore(), 'events', id);
        const unsubscribe = onSnapshot(eventRef, (doc) => {
            const updatedEvent = doc.data();
            if (updatedEvent) {
                setObstacles(updatedEvent.obstacles || []);
            }
        });

        // Cleanup function to unsubscribe from the listener when the component unmounts
        return () => unsubscribe();
    }, [id, getEventById, getGymById, getLeagueById]);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 768,
        maxWidth: '90%', // Ensures responsiveness on smaller screens
        bgcolor: 'background.paper',
        borderRadius: 4,
        border: '2px solid transparent',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto', // Allows scrolling within the modal if content is too long
        maxHeight: '90vh', // Limits the modal height to avoid overflow
    };

    const onDeleteResult = async (resultId) => {
        const db = getFirestore();
        const resultRef = doc(db, "events", event.id, "results", resultId); // Ensure event.id is correctly referenced
        try {
            await deleteDoc(resultRef);
            console.log("Result successfully deleted!");
            // Optionally: Refresh the list of results here
        } catch (error) {
            console.error("Error removing result: ", error);
        }
    };


    // useEffect(() => {
    //     const event = getEventById(id);
    //     setEvent(event);
    //     if (event && event.gym && event.league) {
    //         if (event.gymId) {
    //             const gymData = getGymById(event.gymId);
    //             setGym(gymData);
    //         } else {
    //             setGym(null);
    //         }
    //         const leagueData = getLeagueById(event.league.id);
    //         setLeague(leagueData);
    //     }
    // }, [id, getEventById, getGymById, getLeagueById]);



    //allow user to register (subscribe) to the event
    const handleSubscription = async () => {
        const userRef = doc(getFirestore(), 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
            const currentSubscriptions = userDoc.data()?.eventSubscriptions ?? [];
            let updatedSubscriptions;
            if (isSubscribed) {
                updatedSubscriptions = currentSubscriptions.filter((eventId) => eventId !== id);
            } else {
                updatedSubscriptions = [...currentSubscriptions, id];
            }

            await updateDoc(userRef, { eventSubscriptions: updatedSubscriptions });
            setIsSubscribed(!isSubscribed);
        }
    };


    //allow user to register for an event
    useEffect(() => {
        const event = getEventById(id);
        console.log('useEffect for fetching event, gym and league data is running. Id: ', id);

        setEvent(event);
        if (event) {
            if (event.gym && event.gymId) {
                const gymData = getGymById(event.gymId);
                setGym(gymData);
            } else {
                setGym(null);
            }
            if (event.league && event.league.id) {
                const leagueData = getLeagueById(event.league.id);
                setLeague(leagueData);
            } else {
                setLeague(null);
            }
        }
    }, [id, getEventById, getGymById, getLeagueById]);

    const fetchResults = () => {
        const eventResultsRef = collection(getFirestore(), 'events', id, 'results');
        const unsubscribe = onSnapshot(eventResultsRef, (querySnapshot) => {
            const resultsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setResults(resultsData);
        });

        // Clean up the listener when the component unmounts
        return unsubscribe;
    };

    useEffect(() => {
        const unsubscribe = fetchResults();
        return () => unsubscribe();
    }, [id]);



// console.log('gym on eventDetails'.getGymById);

// fetch subscribed users
    const fetchSubscribedUsers = async () => {
        const usersSnapshot = await getDocs(query(collection(getFirestore(), 'users'), where('eventSubscriptions', 'array-contains', id)));
        const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSubscribedUsers(usersData);
    };

    useEffect(() => {
        fetchSubscribedUsers();
    }, [id]);


    const handleOpen = (event) => {
        setSelectedEvent(event);
        setEditEventOpen(true);
    };

    const handleEditEventClose = () => {
        setSelectedEvent(null);
        setEditEventOpen(false);
    };

    //check if the date has passed
    const isEventDatePassed = event && new Date(event.date) < new Date();

    const deleteResult = async (resultId) => {
        const db = getFirestore();
        const resultRef = doc(db, "events", eventId, "results", resultId);
        try {
            await deleteDoc(resultRef);
            console.log("Result successfully deleted!");
            // Optionally: Refresh the list of results here
        } catch (error) {
            console.error("Error removing result: ", error);
        }
    };

    const onPublish = async () => {
        const firestore = getFirestore();
        const eventRef = doc(firestore, 'events', id); // Assuming 'id' is the ID of the event document

        try {
            await updateDoc(eventRef, {
                resultsStatus: 'Published'
            });
            console.log("Event results published successfully");
        } catch (error) {
            console.error("Error publishing event results: ", error);
        }
    };



    // {event && <div>{console.log("event league", event.league.id)}</div>}
    return (
        <>
        <div>
            {/*// small MUI text link back to the events page */}

            <Link to="/events">
                <ArrowBackIcon />
                Events
            </Link>

            {event ? (
                <>
                    <h2>{event.title}</h2>
                    <div>
                        <IsSubscribedSwitch
                            isSubscribed={isSubscribed}
                            handleSubscription={handleSubscription}
                        />
                        <span>Register for this event</span>
                    </div>
                    <p>Description: <span dangerouslySetInnerHTML={{__html: event.description}}></span></p>
                    <p>Date: {formatDate(event.date)}</p>
                    {/*if there is a price in the document then show it*/}
                    {event.price && <p>Price: {event.price}</p>}
                    {/*if there is an age in the document then show it*/}
                    {event.age && <p>Age: {event.age}</p>}

                    {gym ? (
                        <>
                            <h3>Event Location</h3>
                            {/*/ TODO: Replace the loading text with a generic Skeleton Card/*/}
                            <Suspense fallback={<div>Loading Events...</div>}>
                                <GymCard gym={gym}/>
                            </Suspense>
                        </>
                    ) : (
                        <p>No Location has been set for this event, please contact the event organiser for details</p>
                    )}

                    {league ? (
                        <>

                            <h3>Associated League</h3>
                            <Suspense fallback={<div>Loading Leagues...</div>}>
                                <LeagueCard league={league}/>
                            </Suspense>
                        </>
                    ) : (
                        <p><i>This event is not associated with any leagues.</i></p>
                    )}

                    {subscribedUsers.length > 0 && (
                        <>
                            <h3>Subscribed Ninjas</h3>
                            <ul>
                                {subscribedUsers.map((user, index) => (
                                    <li key={user.id || index}>
                                        <Link
                                            to={`/users/${user.id}`}>{user.ninjaName || user.displayName || user.email}</Link>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}

                    {/*obstacles is an array of items, if there is an array of items on obstacle then loop through the list*/}
                    {obstacles.length > 0 && (
                        <>
                            <h3>Obstacles</h3>
                            <ol>
                                {obstacles.map((obstacle, index) => (
                                    <li key={index}>{obstacle}</li>
                                ))}
                            </ol>
                        </>
                    )}


                    {/*//if results exits then show a H2 of Results*/}
                    {results.length > 0 && <h3>Results</h3>}
                    <ResultsDisplay
                        results={results}
                        onDeleteResult={onDeleteResult}
                        unpublished={event.resultsStatus === 'Unpublished'}
                        onPublish={onPublish}
                    />

                    {/*    Admin controls*/}

                    {event && (currentUser?.uid === event.createdBy || (gym && gym.ownerUid === currentUser?.uid) || (league && league.ownerUid === currentUser?.uid) || userType === 'Admin') &&

                        <>
                            <hr/>

                            {isEventDatePassed && (
                                <SubmitResultsForm eventId={event.id} eventDate={event.date} eventTitle={event.title}
                                                   isEventDatePassed/>)}

                            <Button variant="outlined" onClick={() => setIsObstacleListOpen(true)}>
                                Manage Obstacles
                            </Button>
                            <br/>

                            <EditEventDetails
                                open={editEventOpen}
                                handleClose={handleEditEventClose}
                                event={event}
                                gym={gym}
                                leagues={leagues}
                            />
                            <EventDelete
                                onClick={() => handleDelete(event.id)}
                                size="small"
                                color="error"
                                variant="outlined"
                            >
                                <DeleteIcon/>
                            </EventDelete>


                            <Modal
                                open={isObstacleListOpen}
                                onClose={() => setIsObstacleListOpen(false)}
                                aria-labelledby="manage-obstacles"
                                aria-describedby="manage-obstacles-for-event"
                            >
                                <Box sx={modalStyle}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Manage Obstacles
                                    </Typography>
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setIsObstacleListOpen(false)}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                    <ObstacleList eventId={id} obstacles={obstacles} setObstacles={setObstacles}/>
                                </Box>
                            </Modal>


                        </>
                    }
                </>

            ) : (
                <p>Loading event details...</p>
            )}
        </div>
        </>
    );
};

export default EventDetails;
