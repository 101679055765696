import React from 'react';

import { Modal, Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Login from "./Login";

const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: '0 solid #000',
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
}));

function LoginModal({ open, handleClose }) {
    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <StyledBox>
                {/* You can add your Login/Signup component here */}
                <Login />
                {/*<Button color="primary" variant="contained" onClick={handleClose}>*/}
                {/*    Login*/}
                {/*</Button>*/}
            </StyledBox>
        </StyledModal>
    );
}

export default LoginModal;
