import React, { useState, useEffect, useMemo } from 'react';
import { useGyms } from './useGyms';
import { Card, CardContent, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDataLayer } from '../data/DataLayer';
import GymCard from './GymCard';
import GoogleMapArray from "../api/GoogleMapArray";
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)(({ theme }) => ({
    minWidth: '12rem',
}));

const GymsList = () => {
    const [filters, setFilters] = useState({ country: '', state: '' });
    const [triggerFetchMore, setTriggerFetchMore] = useState(false);
    const { currentUser } = useDataLayer();
    const { gyms, loading, error, setLoading, hasMore } = useGyms(filters, triggerFetchMore);

    // Using useMemo to avoid unnecessary computations on each render
    const countriesFromGyms = useMemo(() => Array.from(new Set(gyms.map(gym => gym.country))), [gyms]);
    const statesFromGyms = useMemo(() => Array.from(new Set(gyms.map(gym => gym.state))), [gyms]);

    useEffect(() => {
        if (currentUser) {
            // Resetting the state filter when the country changes to the currentUser's country
            setFilters({ country: currentUser.country, state: '' });
        }
    }, [currentUser]);

    const handleCountryChange = (event) => {
        // Resetting state filter upon country change
        setFilters({ country: event.target.value, state: '' });
        setTriggerFetchMore(false);
    };

    const handleStateChange = (event) => {
        setFilters({ ...filters, state: event.target.value });
        setTriggerFetchMore(false);
    };

    // Handle loading and error states
    const loadingOrError = loading ? <h4>Loading more gyms...</h4> : error ? <h4>Error loading gyms. Please try again.</h4> : null;

    return (
        <>
            <FormControl>
                <InputLabel id="country-select-label">Country</InputLabel>
                <StyledSelect
                    labelId="country-select-label"
                    id="country-select"
                    value={filters.country || ""}
                    onChange={handleCountryChange}
                >
                    <MenuItem value="">
                        <em>All Countries</em>
                    </MenuItem>
                    {countriesFromGyms.map(country => (
                        <MenuItem key={country} value={country}>{country}</MenuItem>
                    ))}
                </StyledSelect>
            </FormControl>

            <FormControl>
                <InputLabel id="state-select-label">State</InputLabel>
                <StyledSelect
                    labelId="state-select-label"
                    id="state-select"
                    value={filters.state}
                    onChange={handleStateChange}
                >
                    <MenuItem value="">
                        <em>All States</em>
                    </MenuItem>
                    {statesFromGyms.map(state => (
                        <MenuItem key={state} value={state}>{state}</MenuItem>
                    ))}
                </StyledSelect>
            </FormControl>

            <br /><br />

            <GoogleMapArray markers={gyms} />

            <InfiniteScroll
                dataLength={gyms.length}
                next={() => {
                    if (hasMore) {
                        setLoading(true);
                        setTriggerFetchMore(true);
                    }
                }}
                hasMore={hasMore}
                loader={loadingOrError}
            >
                {gyms.map((gym, index) => (
                    <GymCard key={`${gym.name}-${index}`} gym={gym} isLoading={loading} index={index} />
                ))}
            </InfiniteScroll>
        </>
    );
};

export default GymsList;
