import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../FirebaseSetup';
import GymsList from "../../components/gyms/GymsList";
import GymScraper from "../../components/gyms/GymScraper";

function GymsPage() {
    const [gyms, setGyms] = useState([]);

    useEffect(() => {
        // Define a reference to the 'gyms' collection
        const gymsRef = collection(db, 'gyms');

        /**
         * Subscribes to the 'gyms' collection snapshot.
         * @param {firebase.firestore.QuerySnapshot} snapshot
         */
        const unsubscribe = onSnapshot(gymsRef, (snapshot) => {
            const gymsArray = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setGyms(gymsArray);
        });

        // Clean up the subscription
        return () => {
            unsubscribe();
        };
    }, []);

    console.log("Gyms in GymsPage:", { gyms });
    console.log(db); // Should output Firestore instance

    return (
        <div>

            {/*<h2>scraper</h2>*/}
            {/* <GymScraper /> */}

            <h2>MAP</h2>
            {/*<GoogleMapArray markers={gyms}/>*/}

            <h1>Gyms</h1>
            <GymsList gyms={gyms} />
            </div>
    );
}

export default GymsPage;
