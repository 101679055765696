import React, { useState } from 'react';
import { Button, TextField, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { db } from '../../../FirebaseSetup';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

const ObstacleList = ({ eventId, obstacles, setObstacles }) => {
    const [newObstacle, setNewObstacle] = useState('');

    const handleAddObstacle = async (e) => {
        e.preventDefault();
        if (!newObstacle.trim()) return;
        const updatedObstacles = [...obstacles, newObstacle];
        setObstacles(updatedObstacles);
        setNewObstacle('');

        const eventRef = doc(db, 'events', eventId);
        await updateDoc(eventRef, { obstacles: arrayUnion(newObstacle) });
    };

    const moveItem = (index, direction) => {
        const newPosition = index + direction;
        if (newPosition < 0 || newPosition >= obstacles.length) return;
        const newObstacles = [...obstacles];
        [newObstacles[index], newObstacles[newPosition]] = [newObstacles[newPosition], newObstacles[index]];
        setObstacles(newObstacles);

        const eventRef = doc(db, 'events', eventId);
        updateDoc(eventRef, { obstacles: newObstacles });
    };

    const deleteObstacle = async (index) => {
        const updatedObstacles = obstacles.filter((_, idx) => idx !== index);
        setObstacles(updatedObstacles);

        const eventRef = doc(db, 'events', eventId);
        await updateDoc(eventRef, { obstacles: arrayRemove(obstacles[index]) });
    };

    return (
        <div>
            <List>
                {obstacles.map((obstacle, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={obstacle} />
                        <ListItemSecondaryAction>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <IconButton onClick={() => moveItem(index, -1)} aria-label="move up">
                                    <ArrowDropUp />
                                </IconButton>
                                <IconButton onClick={() => moveItem(index, 1)} aria-label="move down">
                                    <ArrowDropDown />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={() => deleteObstacle(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <form onSubmit={handleAddObstacle}>
                <TextField
                    label="Add an Obstacle"
                    value={newObstacle}
                    onChange={(e) => setNewObstacle(e.target.value)}
                    variant="outlined"
                    fullWidth
                    onKeyPress={(e) => e.key === 'Enter' && handleAddObstacle(e)}
                />
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px' }}>
                    Add Obstacle
                </Button>
            </form>
        </div>
    );
};

export default ObstacleList;
