import React from 'react';
import { Grid, Button, useTheme } from '@mui/material';
import { Event, SportsKabaddi, FitnessCenter, ViewAgenda } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

const CollectionLinks = () => {
    const theme = useTheme();

    return (
        <Grid
            container
            spacing={2}
            sx={{ marginTop: '0.5rem' }}
        >
            <Grid item xs={6} sm={3}>
                <Button
                    fullWidth
                    component={RouterLink}
                    to="/events/"
                    variant="outlined"
                    startIcon={<Event />}
                    size="small"
                    sx={{ borderRadius: '9px', marginRight: theme.spacing(1), [theme.breakpoints.up('sm')]: { marginRight: 0 } }}
                >
                    Events
                </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Button
                    fullWidth
                    component={RouterLink}
                    to="/users/"
                    variant="outlined"
                    startIcon={<SportsKabaddi />}
                    size="small"
                    sx={{ borderRadius: '9px', [theme.breakpoints.up('sm')]: { marginLeft: 0 } }}
                >
                    Ninjas
                </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Button
                    fullWidth
                    component={RouterLink}
                    to="/gyms/"
                    variant="outlined"
                    startIcon={<FitnessCenter />}
                    size="small"
                    sx={{ borderRadius: '9px', marginRight: theme.spacing(1), [theme.breakpoints.up('sm')]: { marginRight: 0 } }}
                >
                    Gyms
                </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
                <Button
                    fullWidth
                    component={RouterLink}
                    to="/leagues/"
                    variant="outlined"
                    startIcon={<ViewAgenda />}
                    size="small"
                    sx={{ borderRadius: '9px', [theme.breakpoints.up('sm')]: { marginLeft: 0 } }}
                >
                    Leagues
                </Button>
            </Grid>
        </Grid>
    );
};

export default CollectionLinks;
