//ResultsDisplay.js
import React, { useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CardActions,
    Button,
    Paper, // Importing Paper for the unpublished results container
    Chip, // Importing Chip to show an "Unpublished" label
} from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'; // Icon to indicate unpublished status

import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star'; // Importing Star icon for medals
import { styled } from "@mui/system";
const getMedalColor = (position) => {
    switch (position) {
        case 1: return '#FFD700'; // Gold
        case 2: return '#C0C0C0'; // Silver
        case 3: return '#CD7F32'; // Bronze
        default: return 'transparent';
    }
};

const StyledCard = styled(Card)(({ theme, position }) => ({
    position: 'relative',
    marginBottom: theme.spacing(2),
    borderTop: `10px solid ${getMedalColor(position)}`, // Colored bar based on position
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
}));

const UnpublishedContainer = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.action.disabledBackground, // Use a background color that indicates disabled or unpublished status
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));
const ConfirmationDialog = ({ open, onClose, onConfirm }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to delete this result? This action cannot be undone.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={onConfirm} autoFocus>
                Confirm
            </Button>
        </DialogActions>
    </Dialog>
);

const ResultsDisplay = ({ results, onDeleteResult, unpublished, onPublish }) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedResultId, setSelectedResultId] = useState(null);

    const handleDeleteClick = (resultId) => {
        setSelectedResultId(resultId);
        setConfirmOpen(true);
    };

    const handleConfirmDelete = () => {
        onDeleteResult(selectedResultId);
        setConfirmOpen(false);
    };

    const displayResults = () => {
        // Sort results based on 'position'. If 'position' is not available, place these at the end.
        const sortedResults = results.sort((a, b) => {
            const positionA = a.position || Infinity; // Use Infinity to place results without 'position' at the end
            const positionB = b.position || Infinity;
            return positionA - positionB;
        });

        return sortedResults.map((participant, index) => (
            <StyledCard key={participant.id || index} position={participant.position}>
                <CardContent>
                    <Typography variant="h6">{participant.displayName}</Typography>
                    <Typography variant="body1">Time: {participant.time}</Typography>
                    <Typography variant="body1">Furthest Obstacle: {participant.furthestObstacle}</Typography>
                    {participant.position && <Typography variant="body2">Position: {participant.position}</Typography>}
                </CardContent>
                <CardActions disableSpacing>
                    <StyledIconButton aria-label="delete" onClick={() => onDeleteResult(participant.id)}>
                        <DeleteIcon />
                    </StyledIconButton>
                </CardActions>
            </StyledCard>
        ));
    };

    return (
        <div>
            {unpublished ? (
                <UnpublishedContainer>
                    <Typography variant="h6" gutterBottom>
                        Unpublished Results
                        <Chip
                            icon={<VisibilityOffIcon />}
                            label="Unpublished"
                            color="secondary"
                            size="small"
                            variant="outlined"
                            style={{ marginLeft: 8 }}
                        />
                    </Typography>
                    {displayResults()}
                    <Button variant="contained" color="primary" onClick={onPublish}>
                        Publish All
                    </Button>
                </UnpublishedContainer>
            ) : (
                displayResults()
            )}
            <ConfirmationDialog
                open={confirmOpen}
                onClose={() => setConfirmOpen(false)}
                onConfirm={handleConfirmDelete}
            />
        </div>
    );
};

export default ResultsDisplay;
