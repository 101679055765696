// SubmitResultsForm.js
//TODO: dont allow results until there is an array of obstacles

import React, { useContext, useEffect, useState } from "react";
import AuthContext from '../../../contexts/AuthContext';
import { doc, addDoc, collection, getFirestore, getDocs, getDoc, updateDoc } from 'firebase/firestore';

import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

// Helper function to validate time format
const validateTimeFormat = (time) => {
    return /^([0-1]?[0-9]|2[0-3])\.[0-5][0-9]\.[0-5][0-9]$/.test(time);
};

const SubmitEventResults = ({ eventId, eventDate, eventTitle, ownerId }) => {
    const [userInput, setUserInput] = useState('');
    const [time, setTime] = useState('');
    const [timeError, setTimeError] = useState(false); // Correctly added state for managing time input error
    const [furthestObstacle, setFurthestObstacle] = useState('');
    const [open, setOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const { currentUser } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [obstacles, setObstacles] = useState([]);
    const [event, setEvent] = useState(null);


    useEffect(() => {
        const getUsers = async () => {
            const usersSnapshot = await getDocs(collection(getFirestore(), 'users'));
            setUsers(usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        };
        getUsers();
    }, []);

    useEffect(() => {
        const isOwner = currentUser?.uid === ownerId;
        const isAdmin = currentUser?.role === 'admin';
        setShowForm(isOwner || isAdmin);

        const currentDate = new Date();
        const eventDateObj = new Date(eventDate);
        if (eventDateObj < currentDate) {
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    }, [currentUser, ownerId, eventDate]);

    useEffect(() => {
        const fetchEventAndObstacles = async () => {
            const db = getFirestore();
            const eventDocRef = doc(db, 'events', eventId);
            const docSnap = await getDoc(eventDocRef);

            if (docSnap.exists()) {
                const eventData = docSnap.data();
                setEvent(eventData); // This should now work as expected
                setObstacles(eventData.obstacles || []);
            } else {
                console.log("No such document!");
            }
        };

        fetchEventAndObstacles();
    }, [eventId]);

    const handleModalToggle = () => setOpen(!open);

    const handleSubmit = async () => {
        // Check if the time format is valid before submitting
        if (!validateTimeFormat(time)) {
            setTimeError(true); // Set error state to true
            return; // Prevent form submission
        }

        const db = getFirestore();
        const eventDocRef = doc(db, 'events', eventId);
        const resultsCollectionRef = collection(eventDocRef, 'results');
        setIsSubmitting(true);

        try {
            let result = {};

            // Check if userInput is a string (manual input) or an object (registered user)
            if (typeof userInput === 'string') {
                // Manual input: use the input directly as displayName
                result = {
                    displayName: userInput,
                    time,
                    furthestObstacle,
                };
            } else if (userInput && typeof userInput === 'object') {
                // Registered user: include both userId and the user's display name
                const userDisplayName = userInput.firstName ? `${userInput.firstName} ${userInput.lastName}` : userInput.displayName;

                result = {
                    userId: userInput.id,
                    displayName: userDisplayName,
                    time,
                    furthestObstacle,
                };
            }

            await addDoc(resultsCollectionRef, result);
            console.log('Result submitted:', result);

            // Update the event document to mark results as unpublished
            await updateDoc(eventDocRef, {
                resultsStatus: "Unpublished"
            });

            setOpen(false);
            setUserInput('');
            setTime('');
            setFurthestObstacle('');
        } catch (error) {
            console.error('Error adding document:', error);
        } finally {
            setIsSubmitting(false);
        }

        setTimeError(false); // Reset error state on successful submission
    };

// only show when there is more than one 'obstacles' in the event
    return showForm && obstacles.length > 0 ? (
        <div>
            <Button variant="contained" onClick={handleModalToggle}>
                Enter Results
            </Button>
            <Dialog open={open} onClose={handleModalToggle} fullWidth maxWidth="sm">
                <DialogTitle>Submit Event Results for {eventTitle}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={users}
                                freeSolo
                                getOptionLabel={(option) => typeof option === 'string' ? option : option.firstName + ' ' + option.lastName}
                                renderInput={(params) => <TextField {...params} label="Select or Enter User" />}
                                onInputChange={(event, newInputValue) => {
                                    setUserInput(newInputValue);
                                }}
                                onChange={(event, newValue) => {
                                    // Handles selection from dropdown
                                    if (typeof newValue === 'object' && newValue !== null) {
                                        setUserInput(newValue);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Time"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="obstacle-select-label">Furthest Obstacle</InputLabel>
                                <Select
                                    labelId="obstacle-select-label"
                                    id="obstacle-select"
                                    value={furthestObstacle}
                                    label="Furthest Obstacle"
                                    onChange={(e) => setFurthestObstacle(e.target.value)}
                                >
                                    {obstacles.map((obstacle, index) => (
                                        <MenuItem key={index} value={obstacle}>{obstacle}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                error={timeError}
                                helperText={timeError ? "Please enter time in HH.MM.SS format" : "Format: HH.MM.SS"}
                                label="Time"
                                value={time}
                                onChange={(e) => {
                                    setTime(e.target.value);
                                    if (validateTimeFormat(e.target.value)) {
                                        setTimeError(false); // Reset error state if input is valid
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
                                Submit Result
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    ) : null;
};

export default SubmitEventResults;
