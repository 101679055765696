import React from 'react';
import { useDataLayer } from '../../components/data/DataLayer';  // Import the hook

// MUI Components and Loading Indicator
import EventsList from "../../components/events/EventsList";
import GoogleMapArray from '../../components/api/GoogleMapArray';
import Loading from '../../components/data/Loading';  // Assumed to be a component that renders a loading indicator

function EventsPage() {
    const { events, isAnyDataLoading } = useDataLayer();  // Get events and isAnyDataLoading from the DataLayer

    return (
        <div>
            <h1>Events</h1>
            {isAnyDataLoading ? (
                <Loading />  // Show the Loading component only where the content is loading
            ) : (
                <EventsList events={events} />  // Once loading is complete, display the content
            )}

            <h2>MAP</h2>
            {isAnyDataLoading ? (
                <div style={{ textAlign: 'center' }}>Loading map...</div>  // Optionally, provide a separate loading state for the map if it depends on events data
            ) : (
                <GoogleMapArray markers={events} nestedGym />
            )}
        </div>
    );
}

export default EventsPage;
