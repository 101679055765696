import { useState, useEffect } from 'react';
import { db } from '../../FirebaseSetup';
import {
    collection,
    query,
    orderBy,
    where,
    limit,
    startAfter, // Direct import without alias
    getDocs
} from 'firebase/firestore';

const PAGE_SIZE = 10;

export const useGyms = (filters, triggerFetchMore) => {
    const [gyms, setGyms] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);

    const fetchData = async (lastDocSnapshot) => {
        let qry = query(collection(db, 'gyms'), orderBy('name'));

        if (filters.country) {
            qry = query(qry, where('country', '==', filters.country));
        }

        if (filters.state) {
            qry = query(qry, where('state', '==', filters.state));
        }

        // Incorporate startAfter in the main query composition when there is a lastDocSnapshot
        if (lastDocSnapshot) {
            qry = query(qry, startAfter(lastDocSnapshot), limit(PAGE_SIZE));
        } else {
            qry = query(qry, limit(PAGE_SIZE));
        }

        const snapshot = await getDocs(qry);
        const lastVisibleSnapshot = snapshot.docs[snapshot.docs.length - 1];
        setLastVisible(lastVisibleSnapshot);

        const fetchedGyms = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        if (triggerFetchMore) {
            setGyms(prev => [...prev, ...fetchedGyms]);
        } else {
            setGyms(fetchedGyms);
        }

        setLoading(false);
        setHasMore(fetchedGyms.length > 0);
    };

    useEffect(() => {
        setLoading(true);
        fetchData(); // Initial fetch without a last document
    }, [filters]);

    useEffect(() => {
        if (triggerFetchMore && hasMore && lastVisible) {
            fetchData(lastVisible);
        }
    }, [triggerFetchMore]);

    return { gyms, loading, setLoading, hasMore };
};
