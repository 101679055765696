// fcm.js
// Import the necessary functions from the Firebase v9 SDK
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

// Assuming FirebaseSetup.js exports a properly initialized Firebase app instance
import { app } from '../../FirebaseSetup';

// Initialize the messaging service
const messaging = getMessaging(app);

// This function listens for messages received while the app is in the foreground
onMessage(messaging, (payload) => {
    console.log('Message received in foreground:', payload);
    // You can show a notification or update your app UI here
});

export async function requestNotificationPermission() {
    try {
        // Request permission to send notifications
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            // Get the token
            const token = await getToken(messaging);
            console.log('FCM token:', token);
            return token;
        }
    } catch (error) {
        console.error('Error getting FCM token:', error);
    }
}
