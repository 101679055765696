export const markerIcon = (color = 'red', label = '') => `
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <circle cx="20" cy="20" r="15" fill="${color}" />
    <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="white" font-size="16px">
      ${label}
    </text>
  </svg>
`;

export const clusterIcon = (color = 'green') => `
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
    <circle cx="30" cy="30" r="25" fill="${color}" />
  </svg>
`;

