// EventTabs.js
import React, { useState } from 'react';
import { Box, Divider, Stack, Tabs, Tab } from '@mui/material';
import EventCard from "../events/EventCard";
import { useDataLayer } from '../data/DataLayer';

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const EventTabs = ({ collection, collectionType }) => {
    const { events } = useDataLayer();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if (!collection) {
        return null;
    }

    let upcomingEvents = events.filter(event => {
        const match = collectionType === 'gym' ? event.gymId === collection.id : event.leagueId === collection.id;
        return match && new Date(event.date) >= new Date();
    });

    let pastEvents = events.filter(event => {
        const match = collectionType === 'gym' ? event.gymId === collection.id : event.leagueId === collection.id;
        return match && new Date(event.date) < new Date();
    });

    return (
        <>
            <Divider>Events at {collection.name}</Divider>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="upcoming and past events tabs">
                        <Tab label="Upcoming Events" />
                        <Tab label="Past Events" />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <Stack container spacing={2} columns={{ xs: 4, md: 12 }}>
                        {upcomingEvents.length > 0 ?
                            upcomingEvents.map((event) => (
                                <EventCard key={event.id} event={event} hideGym />
                            )) :
                            <Box>No upcoming events to display.</Box>
                        }
                    </Stack>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Stack container spacing={2} columns={{ xs: 4, md: 12 }}>
                        {pastEvents.length > 0 ?
                            pastEvents.map((event) => (
                                <EventCard key={event.id} event={event} hideGym />
                            )) :
                            <Box>No past events to display.</Box>
                        }
                    </Stack>
                </TabPanel>
            </Box>
        </>
    );
};

export default EventTabs;
